<template>
  <v-container
    fluid
  >
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
  </v-container>
</template>
<script>
import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
import Constants from 'src/constants';

  export default {
    name: 'PosterMyWallOauthStepTwo',
    components: {
        'overlay-alert': OverlayAlert,
    },
    data () {
      return {
        showProgress: false,
        processAlert: '',
      };
    },
    mounted () {
      this.showProgress = true;
      this.processAlert = 'Connecting...';
      setTimeout(() => {
        this.passPartnerAuthDetails();
      }, 2000);
    },
    methods: {
      async passPartnerAuthDetails () {
        this.processAlert = 'Authorizing...';
        await this.$store.dispatch('partners/passPartnerAuthDetails', {
          authorization_code: this.$route.query.code,
          state: 'test',
        }).then(response => {
          this.showProgress = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Partner authentication has been successfully completed.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$router.push({ name: 'Dashboard' });
        }).catch(() => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Partner authentication has failed.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          this.$router.push({ name: 'Dashboard' });
        });
      },
    },
  };
</script>
